import { str } from 'typesafe-routes';

export const subscription = {
  path: ['subscription'],
  children: {
    subscriptionPay: {
      path: ['pay', str.optional('subscriptionPlanUUID')],
    },
    subscriptionPurchaseLink: {
      path: ['purchase-link'],
      children: {
        purchaseLinkUUID: {
          path: [str('purchaseLinkUUID')],
          children: {
            billingAddress: {
              path: ['billing-address'],
            },
          },
        },
      },
    },
    billingAddress: {
      path: ['billing-address'],
    },
    stripeAnonymousCheckout: {
      path: ['stripe-checkout-anonymous'],
    },
  },
};
