import { str } from '@/controllers/router/type-safe-routes/parsers';

export const agreement = {
  path: ['agreement'],
  children: {
    flex: {
      path: ['flex'],
    },
    fulltime: {
      path: ['fulltime'],
    },
    courseSlug: {
      path: [str('courseSlug')],
    },
  },
};
